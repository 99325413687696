$dark-mode-text: #b0e1b8;
$dark-mode-background: #1d1d1d;
$dark-mode-card-background: #2f2f2f;
$dark-mode-border: #4b7552;

$text-color: #414141;
$label-color: #6c7c93;
$primary: #3c984b;
$nav-bar-background: white;
$nav-items-background: white;
$main-background-color: white;
$grey: #d0d0d0;
$light-grey: #eeeeee;
$black: #383838;
$red: #e45c5c;

@import "@truenorthmortgage/olympus/src/sass/style";

html,
body,
#root,
#main {
  height: 100%;
}

#root.modal-open {
  overflow: hidden;
}

.columns {
  margin: 0;
}

body {
  font-family: Gilroy, sans-serif;
  color: $text-color;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

a {
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

p {
  font-size: 0.8rem;
  color: $text-color;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

hr {
  margin: 1.5rem 0;
}

label {
  font-size: 0.8rem;
  color: $label-color;
  font-weight: bold;

  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

.bold {
  font-weight: bold !important;
}

h1 {
  font-family: GilroyBold;
  font-size: 2.75rem;
  color: $primary;
  margin: 0;
}

h2 {
  font-size: 1.5rem;
  color: #000;
  font-weight: bold;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

h3 {
  font-size: 1.125rem;
  font-weight: bold;
  // @media (prefers-color-scheme: dark) {
  //   color: $dark-mode-text;
  // }
}

h4 {
  font-size: 0.8rem;
  font-weight: 800;
  margin: 1rem 0;
}

.primary {
  color: $primary;
}

.negative-number {
  color: $red !important;
  margin-top: 0 !important;
}

.buttons {
  margin-top: 1rem;
}

input:not([type="checkbox"]),
button,
.button {
  -webkit-appearance: none;
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}

input {
  border: 1px solid $grey;
  height: 2.1rem;
}

button,
.button {
  margin: 0;
  text-align: center;
  &.primary,
  &.primary:hover {
    background: $primary !important;
    border-color: $primary;
    color: white;
  }
}

i {
  font-size: 1rem;
  &.error {
    color: $red;
  }
}

.divider {
  height: 3px;
  width: 100%;
  background-color: $primary;
  // @media (prefers-color-scheme: dark) {
  //   background-color: $dark-mode-border;
  // }
}

.divider-grey {
  height: 1px;
  width: 100%;
  background-color: $light-grey;
  // @media (prefers-color-scheme: dark) {
  //   background-color: #000;
  // }
}

.loading {
  width: 100%;
  text-align: center;
  img {
    width: 8rem;
  }
}

.loading-full {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000066;
  z-index: 10;
  img {
    width: 12rem;
  }
}

#main {
  background-color: $primary;
  display: block;
  margin: 0;

  .main-header {
    height: 11rem;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-top: 5rem;
    padding-left: 1rem;

    #breadcrumbs {
      width: 100%;
      max-width: 55rem;
      margin: auto;
      z-index: 1;

      i {
        font-size: 0.6rem;
        color: white;
        // @media (prefers-color-scheme: dark) {
        //   color: $dark-mode-text;
        // }
        margin: 0 0.5rem;
        opacity: 0.4;
      }
      a {
        color: white;
        // @media (prefers-color-scheme: dark) {
        //   color: $dark-mode-text;
        // }
        font-size: 0.8rem;
        font-weight: bold;
      }
      span:not(:last-of-type) a {
        text-decoration: underline;
      }
    }

    img {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  .main-content {
    background-color: $main-background-color;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-background;
    // }
    min-height: calc(100% - 11rem);
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 2rem 1rem;
    display: flex;
    justify-content: center;
    position: relative;
  }
}

#nav-bar {
  .nav-bar {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    height: 5rem;
    width: 100%;
    background-color: $nav-bar-background;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-background;
    // }

    .nav-bar-contents {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      max-width: 55rem;
      margin: auto;
    }
  }
  #nav-items {
    position: fixed;
    font-family: GilroyBold;
    font-size: 1.5rem;
    z-index: 7;

    right: -20rem;
    top: 0;
    height: 100vh;
    width: 20rem;
    background-color: $nav-items-background;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-background;
    // }
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    transition: all 0.25s ease-in-out;

    .close-button {
      color: $primary;
      position: absolute;
      top: 0;
      right: 0;
      padding: 1rem;
    }

    ul {
      list-style-type: none;
      padding: 3rem 0;
      li {
        padding: 0.75rem;
        text-align: center;
        font-weight: 800;

        .link-button {
          font-weight: 800;
          font-size: 100%;
          font-family: GilroyBold;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  i {
    cursor: pointer;
  }

  #nav-items.open {
    right: 0;
  }

  #nav-items.open + .nav-mask {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    width: 100vw;
    height: 100vh;
    pointer-events: all;
    background-color: rgba(0, 0, 0, 0.35);
  }

  .app-logo {
    height: 3rem;
  }
}

.account-info-card,
.login-card,
.setup-card,
.not-found-card {
  h1 {
    margin-top: 2rem;
  }

  h1 + p,
  h1 + div {
    margin: 1rem 0 0 0;
  }
  .img-container {
    width: 100%;
    text-align: center;
    img {
      height: 4rem;
    }
  }

  button,
  input:not([type="checkbox"]) {
    width: 100%;
  }

  .checkbox {
    input:checked ~ .checkmark {
      border: 1px solid $primary;
      background-image: none;
      background-color: $primary;
    }
  }

  .columns {
    margin: 0;
  }

  .column {
    padding: 0;
  }

  .button,
  input,
  .checkbox {
    margin-top: 1rem;
  }
}

.login-card {
  width: 19rem;

  &__link {
    text-align: center;
    margin-top: 1.5rem;
  }
}

.account-info-card {
  max-width: 900px;
  padding-left: 1rem;
  padding-right: 1rem;

  h1 {
    text-align: center;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5rem;
  }
}

.setup-card {
  max-width: 24rem;
}

.not-found-card {
  p {
    font-size: 16px;
    margin: 1rem 0 0 0;
  }

  p:last-child {
    margin-bottom: 1rem;
  }
}

.dashboard,
.heloc,
.account {
  width: 100%;
  height: 100%;
  max-width: 55rem;
  margin-bottom: 5rem;

  td {
    width: 50%;
  }
}

.two-column-table {
  td {
    width: 50%;
  }
}

.four-column-table {
  td {
    width: 25%;
  }
}

.mortgage-view {
  width: 100%;
  height: 100%;
  max-width: 55rem;
  margin-bottom: 5rem;

  .loan-status-detail {
    display: flex;

    .payout-doc-available {
      margin-top: 2px;
    }
  }
}

.dashboard {
  h1 {
    margin-bottom: 2rem;
  }
}

.footer {
  margin: 0 auto;
  width: 100%;
  max-width: 50rem;
  height: 5rem;
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  color: $primary;
  font-size: 0.8rem;

  &__phone-link {
    display: inline-flex;

    & a {
      font-weight: bold;
      margin-left: 0.25rem;
    }
  }
}

.has-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.footer-content {
  margin-top: 4rem;
  width: 100%;
  height: 4rem;
}

.loan-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: 1rem;
  h3,
  p {
    margin: 0;
    color: $black;
    // @media (prefers-color-scheme: dark) {
    //   color: $dark-mode-text;
    // }
  }
  h3 {
    margin-top: 0.5rem;
  }

  .status-details {
    display: flex;
  }
  .loan-row-details {
    margin-top: 2px;
  }
}

.table {
  margin-bottom: 2rem;
  td {
    padding: 1rem !important;
    color: #414141;
    // @media (prefers-color-scheme: dark) {
    //   color: $dark-mode-text !important;
    //   border-bottom-color: #000000 !important;
    // }
  }

  &.primary {
    tr {
      background-color: #f5ffee;
      // @media (prefers-color-scheme: dark) {
      //   background-color: $dark-mode-card-background !important;
      // }
      td {
        &:first-child {
          color: $primary !important;
        }
      }
    }
  }
}

.table-first-child-bold {
  &:not(.transactions) td:first-child {
    font-weight: bold !important;
  }
}

.loan-header {
  margin: 1rem 0 2rem 0;

  h1,
  h3,
  p {
    margin: 0;
  }
  h1 {
    margin: 0.25rem 0;
  }
}
.link-cards {
  width: 100%;
  overflow-x: scroll;
  padding: 1rem 1rem 2rem 1rem;
  display: flex;

  .link-card + .link-card {
    margin-left: 1rem;
  }
}
.link-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 5.5rem;
  width: 8rem;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.16);
  i {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
  }
  span {
    font-size: 0.7rem;
    text-align: center;
  }
}
.cards {
  padding-bottom: 1rem;

  .card {
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-card-background;
    // }
    i,
    span {
      color: $primary;
    }
    span {
      height: 50%;
      display: flex;
      align-items: center;
      // @media (prefers-color-scheme: dark) {
      //   color: $dark-mode-text;
      // }
    }
  }
}
.tab-group {
  .tabs {
    margin: 1rem 0;
    // @media (prefers-color-scheme: dark) {
    //   background-color: $dark-mode-card-background;
    // }
  }

  h2 {
    margin-top: 2rem;
  }
}

.modal,
.filter-modal {
  .modal-box {
    font-size: 0.9rem;
    line-height: 1.3rem;
    background-color: white;
    .modal-header {
      color: $primary;
      font-family: GilroyBold;
      font-size: 1.5rem;
      border: none;
      box-shadow: none;
      padding: 1.5rem 2rem;
      position: relative;
      text-align: center;

      .modal-close {
        width: 1rem;
        height: 2rem;
        top: 0.5rem;
        display: flex;
        align-items: center;
        i {
          font-size: 1.5rem;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        .modal-close {
          position: absolute;
          right: 1rem;
        }
      }
    }
    @media (max-width: 768px) {
      .modal-content {
        padding: 1rem;
      }
      justify-content: flex-start;
    }
    .modal-content {
      width: 100%;
    }

    p.padded {
      font-size: 0.8rem;
      padding-left: 20px;
    }
  }
}

.card .modal span {
  text-align: left !important;
  height: unset;
}

.modal-container {
  background: none;
  z-index: 99998;
  height: 0;
  width: 0;
  opacity: 1;

  .modal-box {
    position: fixed;
    background-color: white;
    z-index: 99998;
    top: calc(100vh + 5rem);
    left: 0;
    right: 0;
    margin: auto;
    max-width: 40rem;
    transition: all 0.3s ease-in-out;
    border-radius: 1rem;
    max-height: calc(100vh - 10%);
    overflow: scroll;
  }
  @media (max-width: 768px) {
    .modal-box {
      height: calc(100vh - 10%);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

.modal-container.open {
  .modal-box {
    top: 25%;
  }
}

.modal-header {
  color: #3c984b;
  font-family: GilroyBold;
  font-size: 1.5rem;
  border: none;
  box-shadow: none;
  padding: 1.5rem 2rem;
  position: relative;
  text-align: center;
  .modal-close {
    position: absolute;
    right: 1rem;
    width: 1rem;
    height: 2rem;
    top: 0.5rem;
    display: flex;
    align-items: center;
    i {
      font-size: 1.5rem;
      cursor: pointer;
    }
  }
}

.modal-mask {
  display: flex;
  flex-wrap: wrap;
  flex-wrap: -webkit-wrap;
  cursor: default;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  position: fixed;
  top: 0px;
  left: 0px;
  overflow-y: auto;
  display: inline-flex;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  z-index: 99998;
  pointer-events: none;
}

.modal-container.open .modal-mask {
  opacity: 1;
  pointer-events: all;
}

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: $primary !important;
}

form {
  input {
    margin-bottom: 1rem;
  }
}

.form {
  .column {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 400px) {
  .tabs {
    overflow-x: scroll;
    justify-content: flex-start !important;
  }
}

.notification {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: auto;
  width: 100% !important;
  max-width: 55rem !important;
  z-index: 10000;

  .notification-background {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .notification-inner {
    display: flex;
    background-image: none !important;

    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2) !important;
    border-radius: 0 !important;
    background-color: white;
    margin: 15px 7px 0 7px !important;

    @media (max-width: 55rem) {
      margin: 1rem 0 !important;
    }
    i {
      font-size: 1.5rem;
      margin-right: 1rem;
    }
    span {
      font-size: 1rem;
    }
  }

  .notification-inner.success {
    i,
    span {
      color: #3c984b;
    }
    .notification-background {
      background-color: rgba(60, 152, 75, 0.1);
    }
    border: 3px solid #3c984b !important;
  }
  .notification-inner.error {
    i,
    span {
      color: #e45c5c;
    }
    .notification-background {
      background-color: rgba(228, 92, 92, 0.1);
    }
    border: 3px solid #e45c5c !important;
  }
}

.errors p {
  margin-bottom: 0;
  color: #e45c5c;
}

.right {
  text-align: right;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: $primary;
  cursor: pointer;
}

.accordion {
  tr.faux-row.accordion-header {
    background-color: #d0d0d0;
    cursor: pointer;
  }
  tr.faux-row.accordion-header > td.faux-cell {
    font-weight: bold;
  }
}

.row-header {
  border-bottom: 3px solid $primary;

  td.faux-cell.text {
    font-size: 1.5rem;
    font-weight: bold;
  }
}

.width-20-percent {
  width: 20%;
}

.min-width-td {
  width: 0;
}

.unset-all-style {
  all: unset !important;
}

.x-scrollable {
  overflow-x: scroll;
}

.flex {
  display: flex;
}
